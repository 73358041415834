.textfield-md {
	width: "20vw";
	max-width: "500px";
	margin-right: "16px";
}

@media screen and (min-width: 992px) and (max-width: 1480px) {
	.col-1480-3 {
		flex: 0 0 auto;
		width: 33.333%;
	}

	.mt-1480-16 {
		margin-top: 16px !important;
	}
}

@media screen and (max-width: 992px) {
	.textfield-md {
		width: 100%;
		max-width: none;
		margin-left: 0;
		margin-right: 0;
	}
}
