div.ctm-sm-buttons {
	justify-content: flex-end;
}

@media screen and (max-width: 768px) {
	div.ctm-sm-buttons {
		justify-content: flex-start;
	}

	div.ctm-sm-delete {
		margin-top: 1rem;
	}
}
