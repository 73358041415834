div.controls-large,
div.controls-large-left {
	display: block;
}

div.controls-small,
div.controls-small-left {
	display: none;
}

@media screen and (max-width: 991px) {
	.bottombar-btn-resizable {
		width: 100%;
	}

	div.controls-large-left {
		display: none;
	}

	div.controls-small-left {
		display: block;
	}
}

@media screen and (max-width: 767px) {
	.bottombar-btn-resizable-left {
		width: 100%;
	}

	div.controls-large {
		display: none;
	}

	div.controls-small {
		display: block;
	}
}
