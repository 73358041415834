@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

:root {
	/* Colors: */
	--white: #ffffff;
	--grey-field: #f8f8f8;
	--grey-background: #f5f5f5;
	--grey-1: #cfcfcf;
	--grey-2: #aeaeae;
	--darkgrey: #6e6e6e;
	--black: #202020;
	--blue: #2480dc;
	--purple: #840088;
	--orange: #e87400;
	--yellow: #ffb100;
	--yellow-o10: #ffb1001a;
	--yellow-o20: #ffb10033;
	--red: #d22a16;
	--red-o10: #d22a161a;
	--red-o20: #d22a1633;
	--green: #16b240;
	--green-o10: #16b2401a;
	--green-o20: #16b24033;
	--gold-yellow: #fff6e5;
	--gold-yellow-hover: #ffeabf;
}

html {
	width: 100vw;
	height: 100vh;
	overflow-x: hidden;
}

body {
	height: 100vh;
	overflow-x: hidden;
}

#root,
#root > div {
	height: 100vh;
}

/* Width */
.w-90 {
	width: 90%;
}

/* Margin */
.margin-top-children-16 > * {
	margin-top: 16px;
}

/* Padding */
.padding-left-0 {
	padding-left: 0;
}

/* Modals */
.modal-footer-no-border-top > .modal-content > .modal-footer {
	border-top: none;
}

.modal-padding > .modal-content > .modal-body {
	padding: 24px 32px 0px 32px;
}

.modal-padding > .modal-content > .modal-footer {
	padding: 0px 32px 32px 32px;
}

/* General */
.space-children-24 > * {
	margin-right: 24px;
}
.space-children-24 > *:last-child {
	margin-right: 0px;
}

.space-children-12 > * {
	margin-right: 12px;
}
.space-children-12 > *:last-child {
	margin-right: 0px;
}

.space-children-32 > * {
	margin-right: 32px;
}
.space-children-32 > *:last-child {
	margin-right: 0px;
}

.space-between {
	justify-content: space-between;
}

/** Style **/
.ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

/** OVERRIDE CLASSI **/

.rbt-input-main {
	box-shadow: none !important;
	border-color: var(--grey-1) !important;
}

.rbt-aux {
	position: absolute;
	top: 0px;
	right: 6px;
}

.rbt-aux > button > .rbt-close-content {
	display: none;
}

.toast-container {
	z-index: 10000;
}

.toast {
	border-radius: 6px;
}

.toast-header {
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
	border-bottom: none;
	background-color: transparent;
}

.toast-header * {
	color: white;
}

.toast-header .btn-close {
	outline: 0;
	opacity: 1;
	box-shadow: none !important;
	color: white;
	background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='white' d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>")
		center/1em auto no-repeat;
}

.toast-header .btn-close:hover {
	color: white;
	outline: 0;
	opacity: 1;
}

.toast-body {
	padding-top: 0px;
}

/** cursor **/
.cursor-default {
	cursor: default;
}

.cursor-pointer {
	cursor: pointer;
}

/** Hover **/
.hover-black:hover {
	color: var(--black) !important;
	transition: all 0.4s;
}

/** Animations **/
.rotate-180 {
	transform: rotate(180deg);
}





.min-width-1318 {
	min-width: 1318px;
}

.min-width-1301 {
	min-width: 1301px;
}

.min-width-1192 {
	min-width: 1192px;
}
.min-width-742 {
	min-width: 742px;
}

@media screen and (max-width: 1330px) {
	.scroll-1330 {
		min-width: 100%;
		overflow-x: scroll;
	}
}

@media screen and (max-width: 1440px) {
	.scroll-1440 {
		min-width: 100%;
		overflow-x: scroll;
	}
}