.textfield-md {
	width: "20vw";
	max-width: "500px";
}

@media screen and (max-width: 992px) {
	.textfield-md {
		width: 100%;
		max-width: none;
	}
}
